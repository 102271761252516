<template>
    <div class="default-locale-changer-container">
        <div class="default-locale-changer" @click.prevent="toggleLocaleChanger()">
            <CommonComponentsSVGHeaderWeb :class="{ active: isExpand }" />
            <Transition name="movedown">
                <!-- Selector -->
                <div v-if="isExpand" class="default-locale-changer-list">
                    <div
                        v-for="(item, index) in locales"
                        :key="index"
                        class="default-locale-changer-list-item"
                        :class="{ active: curIndex === index }"
                        @click="toggleLocale({ key: item.locale })"
                    >
                        {{ item.label }}
                    </div>
                </div>
                <!-- 鼠标点击外部，隐藏Selector -->
            </Transition>
        </div>
        <div v-if="isExpand" class="default-locale-changer-mask" @click.prevent="toggleLocaleChanger()" />
    </div>
</template>

<script lang="ts" setup>
    import Cookies from 'js-cookie';

    interface LocaleItem {
        locale: string;
        label: string;
        icon: string;
    }

    const locale = useLocaleLanguage();
    const locales: Array<LocaleItem> = useLocales();

    const curIndex = ref<number>(-1);
    const isExpand = ref<boolean>(false);
    onMounted(() => {
        const i = locales.findIndex((localeItem) => {
            return locale.value === localeItem.locale;
        });
        if (i !== -1) curIndex.value = i;
    });

    const toggleLocale = ({ key }: { key: string }) => {
        localStorage.setItem('lang', key);
        Cookies.set('lang', key);
        useLocaleLanguage();
        locale.value = key;
        location.reload();
    };

    const toggleLocaleChanger = () => {
        isExpand.value = !isExpand.value;
    };

    const closeLocaleChanger = () => {
        isExpand.value = false;
    };

    const { $bus } = useNuxtApp();

    onMounted(() => {
        $bus.$on('close:localeChanger', () => {
            closeLocaleChanger();
        });
    });
</script>

<style lang="less" scoped>
    .movedown-enter-active,
    .movedown-leave-active {
        transition: all 0.5s ease;
    }

    .movedown-enter-from,
    .movedown-leave-to {
        opacity: 0;
        transform: translateY(-10px);
    }

    .default-locale-changer-container {
        height: 100%;
        color: #000;

        .default-locale-changer {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &-list {
                z-index: 301;
                position: absolute;
                right: 0;
                top: 100%;

                font-family: HarmonyOS Sans SC;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;

                background-color: rgba(255, 255, 255, 1);
                border-radius: 2px;

                display: flex;
                flex-direction: column;

                &-item {
                    white-space: nowrap;
                    padding: 8px 12px;
                    user-select: none;

                    &:hover {
                        background-color: rgba(0, 50, 255, 0.2);
                    }

                    &.active {
                        background-color: rgba(0, 50, 255, 1);
                        color: rgba(255, 255, 255);
                    }
                    &:first-child {
                        border-top-right-radius: 2px;
                        border-top-left-radius: 2px;
                    }
                    &:last-child {
                        border-bottom-right-radius: 2px;
                        border-bottom-left-radius: 2px;
                    }
                }
            }

            &-mask {
                z-index: 300;
                // background-color: rgba(0, 50, 255, 0.5);
                // border: 1px solid white;
                position: fixed;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
            }
        }
    }
</style>
