<template>
    <div class="apply-for-trial-form cn" :class="formType">
        <AForm
            ref="applyForm"
            :model="formState.user"
            name="apply-for-trial"
            :rules="rules"
            :validate-messages="validateMessages"
            :layout="formType === 'mobile' ? 'vertical' : 'horizontal'"
            :label-col="formState.labelCol"
            :wrapper-col="formState.wrapperCol"
        >
            <div class="scroll-y-container">
                <AFormItem
                    v-for="(formItem, index) in filteredFormItems"
                    :key="formItem.key"
                    :name="formItem.key"
                    :label="$t(formItem.t)"
                >
                    <AInput
                        v-if="formItem.inputType === 'text'"
                        v-model:value="formState.user[formItem.key]"
                        :placeholder="$t(formItem.tPlaceholder)"
                        :type="formItem.key === 'code' ? 'number' : 'text'"
                        :maxlength="99"
                        autocomplete="off"
                    />
                    <AInput
                        v-else-if="formItem.inputType === 'tel'"
                        v-model:value="formState.user[formItem.key]"
                        class="tel-input"
                        :placeholder="$t(formItem.tPlaceholder)"
                        type="number"
                        :maxlength="99"
                        autocomplete="off"
                    >
                        <template #addonBefore>
                            <ASelect
                                v-model:value="formState.user.tel_code"
                                size="small"
                                :style="{ width: '85px' }"
                                :get-popup-container="(triggerNode) => triggerNode.parentNode"
                                @change="telCodeChange"
                            >
                                <ASelectOption
                                    v-for="telCode in formState.createData.tel_code"
                                    :key="telCode.id"
                                    :value="telCode.code"
                                >
                                    <ATooltip placement="topLeft">
                                        <template #title>
                                            <span> {{ telCode.name }}</span>
                                        </template>
                                        <span> {{ telCode.name }}</span>
                                    </ATooltip>
                                </ASelectOption>
                            </ASelect>
                        </template>
                        <template v-if="isChinese" #addonAfter>
                            <AButton type="link" :disabled="validateState.isSended" @click="sendCode">{{
                                sendCodeBtnText
                            }}</AButton>
                        </template>
                    </AInput>
                    <ASelect
                        v-else-if="formItem.inputType === 'selector'"
                        v-model:value="formState.user.company_type"
                        :placeholder="$t(formItem.tPlaceholder)"
                        :get-popup-container="(triggerNode) => triggerNode.parentNode"
                    >
                        <ASelectOption
                            v-for="companyType in formState.createData.company_type"
                            :key="companyType.cid"
                            :value="companyType.cid"
                        >
                            {{ companyType.name }}
                        </ASelectOption>
                    </ASelect>
                    <ASelect
                        v-else-if="formItem.inputType === 'selector1'"
                        v-model:value="formState.user.company_scale"
                        :placeholder="$t(formItem.tPlaceholder)"
                        :get-popup-container="(triggerNode) => triggerNode.parentNode"
                    >
                        <ASelectOption
                            v-for="companyScale in formState.createData.company_scale"
                            :key="companyScale.cid"
                            :value="companyScale.cid"
                        >
                            {{ companyScale.name }}
                        </ASelectOption>
                    </ASelect>
                </AFormItem>
                <AFormItem name="policyConfirm">
                    <ACheckbox v-model:checked="formState.policyConfirm">
                        <div class="policy-confirm-checkbox-text">
                            <span>{{ $t('global_home_header_form_conform_text') }}</span>
                            <CommonComponentsLink
                                :link-text="'global_home_header_form_conform_policy'"
                                @click.prevent="openPolicyFile('privacy')"
                            />
                        </div>
                    </ACheckbox>
                </AFormItem>
            </div>
            <div class="fixed-bottom-container">
                <AFormItem class="apply-for-trial-form-submit-btn" :wrapper-col="{ span: 24 }">
                    <CommonComponentsButtonLine
                        ref="submitBtn"
                        transparent
                        light
                        :button-text="t('global_link_button_1')"
                        :disabled="!formState.isFinished"
                        @click="onSubmit"
                    />
                </AFormItem>
            </div>
        </AForm>
    </div>
</template>

<script lang="ts" setup>
    import { useRouter } from 'vue-router';
    import Http from '@tools/https';
    import { ref, onMounted, watch } from 'vue';
    import type { Rule, FormInstance } from 'ant-design-vue/es/form';
    import { gsap, Timeline } from 'gsap';
    import { ScrollTrigger } from 'gsap/all';
    import { openPolicyFile } from '~~/composables/usePolicyFile.ts';
    import i18n from '@/locales';
    import message from '~~/antd//message/adapter';

    gsap.registerPlugin(ScrollTrigger);
    const { t } = i18n.global;
    const router = useRouter();

    const props = withDefaults(
        defineProps<{
            formType?: 'dialog' | 'mobile';
        }>(),
        { formType: 'dialog' },
    );

    const isFsYxtNormal = ref<boolean>(false); // 三方纷享客是否正常加载
    let fsYxtFormId = ''; // 三方纷享客表单id
    const submitBtn = ref<HTMLButtonElement | null>(null);
    const isSubmitting = ref<boolean>(false);

    const fsYxtCompanyTypeMap = {
        品牌商: '1',
        制造商: '2',
        面辅料商: '1692346140675',
        院校: '1692346140676',
        个人: '1692346140677',
        其他: 'other',
    };
    const fsYxtCompanyScaleMap = {
        '2000万以下': '1',
        '2000万~5000万': '2',
        '5000万~1亿': '8acf578469da2a65',
        '1亿以上': 'ee34aa5b8454cd13',
    };

    const emits = defineEmits<{
        (e: 'update:modelValue', value: boolean): void;
    }>();
    watch(
        isSubmitting,
        (value) => {
            emits('update:modelValue', value);
        },
        { immediate: true },
    );

    let countTimer: Timeline | null = null;

    // ant-vue-design 校验信息模版，https://github.com/vueComponent/ant-design-vue/blob/main/components/form/utils/messages.ts
    /* eslint-disable  */
    const validateMessages = reactive({
        required: () => t('global_validate_message_0') + '${label}!',
    });
    /* eslint-enable */
    const validatePhone = async (_rule: Rule, value: string) => {
        if (value === '') {
            return Promise.reject(t('global_validate_message_1'));
        } else if (formState.user.tel_code !== 86 || /^1[3456789]\d{9}$/.test(value)) {
            return Promise.resolve();
        } else {
            return Promise.reject(t('global_validate_message_2'));
        }
    };

    const validateEmail = async (_rule: Rule, value: string) => {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value === '') {
            return Promise.reject(t('global_validate_message_3'));
        } else if (!re.test(String(value).toLowerCase())) {
            return Promise.reject(t('global_validate_message_4'));
        } else {
            return Promise.resolve();
        }
    };

    // 表单信息
    const formState = reactive<{
        createData: {
            tel_code: never[];
            company_type: never[];
            company_scale: never[];
        };
        labelCol: {
            span: number;
        };
        wrapperCol: {
            span: number;
        };
        user: {
            name: string;
            tel_code: number;
            phone: string;
            code: string;
            email: string;
            company_name: string;
            company_type: number;
            company_scale: number;
        };
        policyConfirm: boolean;
        isFinished: boolean;
        formConfig: Array<{
            key: 'name' | 'phone' | 'code' | 'email' | 'company_name';
            dataType: string;
            inputType: string;
            name: string;
            t: string;
            tPlaceholder: string;
        }>;
    }>({
        createData: { tel_code: [], company_type: [], company_scale: [] }, // 异步数据
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
        user: {
            name: '',
            tel_code: 86,
            phone: '',
            code: '',
            email: '',
            company_name: '',
        },
        // companyTypeOption: [],
        policyConfirm: false,
        isFinished: false,
        // inputType 区分页面上输入框的样式
        formConfig: [
            {
                key: 'name',
                dataType: 'string',
                inputType: 'text',
                name: '联系人',
                t: 'global_home_header_form_key_1',
                tPlaceholder: 'global_home_header_form_placeholder_1',
            },
            {
                key: 'phone',
                dataType: 'tel',
                inputType: 'tel',
                name: '联系电话',
                t: 'global_home_header_form_key_2',
                tPlaceholder: 'global_home_header_form_placeholder_2',
            },
            {
                key: 'code',
                dataType: 'text',
                inputType: 'text',
                name: '验证码',
                t: 'global_home_header_form_key_3',
                tPlaceholder: 'global_home_header_form_placeholder_3',
            },
            {
                key: 'email',
                dataType: 'text',
                inputType: 'text',
                name: '电子邮箱',
                t: 'global_home_header_form_key_4',
                tPlaceholder: 'global_home_header_form_placeholder_4',
            },
            {
                key: 'company_name',
                dataType: 'text',
                inputType: 'text',
                name: '企业名称',
                t: 'global_home_header_form_key_5',
                tPlaceholder: 'global_home_header_form_placeholder_5',
            },
            {
                key: 'company_type',
                dataType: 'text',
                inputType: 'selector',
                name: '企业类型',
                t: 'global_home_header_form_key_6',
                tPlaceholder: 'global_home_header_form_placeholder_6',
            },
            {
                key: 'company_scale',
                dataType: 'text',
                inputType: 'selector1',
                name: '营收规模',
                t: 'global_home_header_form_key_7',
                tPlaceholder: 'global_home_header_form_placeholder_7',
            },
        ],
    });

    // 区号为86时需要通过手机验证
    const isChinese = computed(() => {
        return formState.user.tel_code === 86;
    });
    const filteredFormItems = computed(() => {
        if (!isChinese.value) {
            return formState.formConfig.filter((item) => {
                return item.key !== 'code';
            });
        } else {
            return formState.formConfig;
        }
    });

    // 校验规则，设置是否必需
    const rules: Record<string, Rule[]> = {
        name: [{ required: true, type: 'string', trigger: 'blur' }],
        phone: [{ required: true, type: 'string', validator: validatePhone, trigger: ['blur'] }],
        code: [{ required: isChinese.value, type: 'string', trigger: ['blur'] }],
        email: [{ required: true, type: 'email', validator: validateEmail, trigger: 'blur' }],
        company_name: [{ required: true, type: 'string', trigger: 'blur' }],
        company_type: [{ required: true }],
        company_scale: [{ required: true }],
    };

    // 验证码发送倒计时以及发送状态控制
    const originalValidateState = {
        count: 60,
        isSended: false,
        isSending: false,
    };
    const validateState = reactive({ ...originalValidateState });
    const sendCodeBtnText = computed(() => {
        return validateState.isSended ? `${validateState.count.toFixed()}s` : t('global_link_button_2');
    });
    // 发送验证码
    const sendCode = () => {
        // validatePhone(rules.phone[0], formState.user.phone).then(async () => {
        applyForm.value?.validateFields(['phone']).then(async () => {
            if (!validateState.isSending && !validateState.isSended) {
                validateState.isSending = true;
                // 倒计时60s
                countTimer = gsap.timeline();
                countTimer
                    .to(validateState, { duration: validateState.count, count: 0, ease: 'none' })
                    .set(validateState, { ...originalValidateState });
                if (window.FsYxt && isFsYxtNormal.value && formState.user.tel_code === 86) {
                    // 走纷享客验证码
                    window.FsYxt.FormSDK.sendSMCode({
                        formId: fsYxtFormId,
                        mobile: formState.user.phone,
                        success: () => {
                            validateState.isSended = true;
                            validateState.isSending = false;
                        },
                        fail: () => {
                            message.warning(t('global_send_vcode_fail'));
                            validateState.isSending = false;
                        },
                    });
                    return;
                }
                const res = await Http.post('/account/register/verify_code', { tel: formState.user.phone });
                console.log(res);
                validateState.isSending = false;
                if (res?.state) validateState.isSended = true;
                else message.warning(t('global_send_vcode_fail'));
            }
        });
    };

    // 表单提交回调
    const onSubmit = () => {
        applyForm.value.validate().then(async () => {
            if (isSubmitting.value) return;
            isSubmitting.value = true;
            const { name, email, tel_code, phone, code, company_name, company_type, company_scale } = formState.user;
            if (window.FsYxt && isFsYxtNormal.value && tel_code === 86) {
                const company_type_text = formState.createData.company_type.filter(
                    (item) => company_type === item.cid,
                )[0].name;
                const company_scale_text = formState.createData.company_scale.filter(
                    (item) => company_scale === item.cid,
                )[0].name;
                // 走纷享客
                const singleChoice: Record<string, any> = {};
                let fsYxtCompanyType = fsYxtCompanyTypeMap[company_type_text];
                if (!fsYxtCompanyType) fsYxtCompanyType = 'other';
                if (fsYxtCompanyType === 'other') fsYxtCompanyType = `other:${company_type_text}`;
                else singleChoice.text7_1692346140644_other = null;

                let fsYxtCompanyScale = fsYxtCompanyScaleMap[company_scale_text];

                singleChoice.text7_1692346140644 = fsYxtCompanyType;
                singleChoice.text7_be10222923c235de = fsYxtCompanyScale;

                window.FsYxt.FormSDK.submitForm({
                    formId: fsYxtFormId,
                    data: {
                        name,
                        phone,
                        phoneVerifyCode: code,
                        email,
                        companyName: company_name,
                        companyType: company_type_text, // 传名称
                        companyScale: company_scale_text,
                        singleChoice,
                    },
                    success: submitSuccess,
                    fail: (res) => {
                        message.error(res.errMsg);
                        isSubmitting.value = false;
                    },
                });
                return;
            }
            const res = await Http.post('/account/apply', {
                name,
                email,
                tel_code, // 电话区号
                phone,
                code,
                company_name,
                company_type, // 传cid
                company_scale,
            });

            if (res.state) {
                submitSuccess();
            } else {
                isSubmitting.value = false;
                message.error(res.message);
            }
        });
    };

    const resetVcode = () => {
        countTimer?.clear();
        validateState.isSended = false;
        validateState.isSending = false;
        validateState.count = 60;
    };
    const { $bus } = useNuxtApp();
    const submitSuccess = () => {
        message.success(t('global_apply_trial_submit_success'));
        isSubmitting.value = false;
        applyForm.value?.resetFields();
        formState.isFinished = false;
        resetVcode();

        if (props.formType === 'mobile') {
            // 移动 端，关闭Header
            router.push('/thanks');
            $bus.$emit('close:mobileNav');
        } else {
            // PC 端，关闭弹窗
            router.push('/thanks');
            $bus.$emit('close:applyModal');
        }
    };
    const telCodeChange = () => {
        formState.user.code = formState.user.tel_code === 86 ? '' : '888888';
    };

    // 校验纷享客是否正常
    const checkFsYxt = () => {
        window.FsYxt.FormSDK.getFieldDescriptions({
            formId: fsYxtFormId,
            success: () => {
                isFsYxtNormal.value = true;
            },
            fail: (err) => {
                console.log('Error', err);
                isFsYxtNormal.value = false;
            },
        });
    };

    const applyForm = ref<FormInstance | null>(null);

    onBeforeMount(async () => {
        const res = await Http.get('/account/apply/create');
        formState.createData.tel_code = res.data.create_info.tel_code;
        formState.createData.company_type = res.data.create_info.company_type;
        formState.createData.company_scale = res.data.create_info.company_sales;
    });
    onMounted(() => {
        watch([() => formState.policyConfirm, formState.user], ([value0, value1]) => {
            if (
                value0 === true &&
                Object.keys(value1).filter((key) => {
                    if (key === 'policyConfirm') return false;
                    return !value1[key];
                }).length === 0
            )
                applyForm.value.validate().then(() => {
                    formState.isFinished = true;
                });
            else formState.isFinished = false;
        });
        useFormSDK().then((formId) => {
            fsYxtFormId = formId;
            checkFsYxt();
        });
    });
</script>
