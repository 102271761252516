<template>
    <div ref="customAModal" class="custom-a-modal">
        <AModal
            ref="modalRef"
            v-model:visible="visible"
            :centered="true"
            :get-container="() => $refs.customAModal as HTMLElement"
            :footer="null"
            :width="isCN ? '520px' : '850px'"
            @cancel="handleModalCancel"
        >
            <div class="custom-a-modal-title">
                <div class="custom-a-modal-title-left">{{ $t('global_link_button_8') }}</div>
                <!-- <div class="custom-a-modal-title-right">{{ $t('global_home_header_form_title_1_right') }}</div> -->
            </div>
            <CommonComponentsModalApplyForm v-model="isSubmitting" />
            <div class="apply-for-trial-help">
                {{ $t('global_home_header_form_help') }}
            </div>
            <template #closeIcon>
                <div class="custom-a-modal-close">
                    <CommonComponentsSVGCommonCross />
                </div>
            </template>
            <div v-show="visible && isSubmitting" class="loading-mask">
                <ASpin />
            </div>
        </AModal>
    </div>
</template>

<script lang="ts" setup>
    import { computed, reactive, onMounted, onUnmounted, ref } from 'vue';
    const visible = ref<boolean>(false);
    const { $bus, $lenis } = useNuxtApp();
    const isSubmitting = ref<boolean>(false);
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    const isCN = ifCNLocale();

    // 打开与关闭弹窗
    const showModal = () => {
        // 滚动阻塞
        $lenis.stop();
        visible.value = true;
    };

    const handleModalCancel = () => {
        // 滚动启用
        $lenis.start();
        visible.value = false;
    };
    defineExpose({ showModal, handleModalCancel });

    onMounted(() => {
        // 监听事件
        $bus.$on('show:applyModal', () => {
            showModal();
        });
        $bus.$on('close:applyModal', () => {
            handleModalCancel();
        });
    });
</script>
<style lang="less">
    .custom-a-modal {
        .apply-for-trial-form.cn.dialog {
            .ant-input {
                background-color: transparent;
            }
        }
        .apply-for-trial-form.en.dialog {
            .ant-input {
                background-color: transparent;
            }
        }
    }
</style>
<style lang="less" scoped>
    @modal-border-radius: 20px;

    .custom-a-modal {
        font-family: Montserrat, 'HarmonyOS Sans SC';
        user-select: none;
        &-title {
            height: .convert(84px) [ @vw];
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            color: #fff;
            font-style: normal;
            line-height: normal;
            &-left {
                font-size: 32px;
                font-weight: 500;
            }
            &-right {
                font-size: 24px;
                font-weight: 400;
            }
        }

        :deep(.ant-modal-content) {
            color: #ffffff;
            margin: 0 auto;
            border-radius: @modal-border-radius;
            background: rgba(0, 0, 0, 1) !important;
            // backdrop-filter: blur(20px);
            border: 0;
            position: relative;
            .ant-modal-close {
                // svg {
                //     color: #fff;
                // }
                .custom-a-modal-close {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                }
                & {
                    opacity: 0.5;
                    transition: all 0.5s ease-in-out;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
            .ant-modal-body {
                padding-bottom: 40px;
                padding-left: 15px;
                padding-right: 10px;
            }
        }

        .apply-for-trial-help {
            position: absolute;
            border-radius: 0 0 @modal-border-radius @modal-border-radius;
            bottom: 0;
            left: 0;
            height: 40px;
            width: 100%;
            background: rgba(255, 255, 255, 0.2);

            display: flex;
            justify-content: center;
            align-items: center;
            user-select: text;
        }
        .loading-mask {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.6);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
        }
    }
    :deep(.ant-modal-mask) {
        backdrop-filter: blur(20px);
        background-color: rgba(102, 102, 102, 0.4);
    }
</style>
<style lang="less">
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>
