import dayjs from 'dayjs';
/**
 * 格式化日期函数
 * @param date 日期
 * @param formatStr 格式化
 * @returns 格式化后的日期
 */
export const formatTime = (date: string | Date, formatStr = 'YYYY-MM-DD HH:mm:ss'): string => {
    return dayjs(date).format(formatStr);
};

// 延时函数
export const sleep = (sleepTime = 0) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, sleepTime);
    });
};
