export default {
    userTypeList: [
        {
            label: 'Individual User',
            value: 'Individual User',
        },
        {
            label: 'Business User',
            value: 'Business User',
        },
    ],
    companySizeList: [
        {
            label: 'Just me',
            value: 'Just me',
        },
        {
            label: '1-5 employees',
            value: '1-5 employees',
        },
        {
            label: '5-20 employees',
            value: '5-20 employees',
        },
        {
            label: '20-100 employees',
            value: '20-100 employees',
        },
        {
            label: '100-500 employees',
            value: '100-500 employees',
        },
        {
            label: '500-1000 employees',
            value: '500-1000 employees',
        },
        {
            label: '1000-5000 employees',
            value: '1000-5000 employees',
        },
        {
            label: '5000+ employees',
            value: '5000+ employees',
        },
    ],
    marketSegmentList: [
        {
            label: 'Accessoires',
            value: 'Accessoires',
            displayOrder: 0,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Fashion Event',
            value: 'Anlassmode',
            displayOrder: 1,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Corporate Wear',
            value: 'Corporate Wear',
            displayOrder: 2,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Womenswear',
            value: 'DOB',
            displayOrder: 3,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Menswear',
            value: 'HAKA',
            displayOrder: 4,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Jeanswear',
            value: 'Jeanswear',
            displayOrder: 6,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: "Children's wear",
            value: 'KIKO',
            displayOrder: 7,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Leatherwear',
            value: 'Lederbekleidung',
            displayOrder: 8,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Sportswear/Casual Wear',
            value: 'Sportswear/Casual Wear',
            displayOrder: 10,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Trends Forecast',
            value: 'Trends',
            displayOrder: 11,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Lingerie/swim wear',
            value: 'Wäsche-/Bademode',
            displayOrder: 12,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Other',
            value: 'Sonstiges',
            displayOrder: 13,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Manufacturing',
            value: 'Manufacturing',
            displayOrder: 14,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Education',
            value: 'Education',
            displayOrder: 15,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Service',
            value: 'Service',
            displayOrder: 16,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Reselling',
            value: 'Reselling',
            displayOrder: 17,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Technology',
            value: 'Technology',
            displayOrder: 18,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Video Games',
            value: 'Video Games',
            displayOrder: 19,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Animation',
            value: 'Animation',
            displayOrder: 20,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Digital Human',
            value: 'Digital Human',
            displayOrder: 21,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Cinematic Production',
            value: 'Cinematic Production',
            displayOrder: 22,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
    ],
    businessList: [
        {
            label: 'Bespoke tailoring',
            value: 'Maßanfertigung',
            displayOrder: 6,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Freelance designer',
            value: 'freiberuflicher Designer',
            displayOrder: 1,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Garment manufacturer',
            value: 'Konfektionär',
            displayOrder: 5,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Mail order (online/catalogue)',
            value: 'Versandhandel (Internet/Katalog)',
            displayOrder: 8,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Retail',
            value: 'Einzelhandel',
            displayOrder: 0,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Sales representative',
            value: 'Handelsvertreter',
            displayOrder: 4,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Trade brand',
            value: 'Handelsmarke',
            displayOrder: 3,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Trend for casting office',
            value: 'Trend-Büro/Design-Studio',
            displayOrder: 7,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Upstream supplier of the industry',
            value: 'Vorlieferant der Branche',
            displayOrder: 9,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Wholesale',
            value: 'Großhandel',
            displayOrder: 2,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
    ],
    positionList: [
        {
            label: 'Agent',
            value: 'Agent',
            displayOrder: 0,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Buyer',
            value: 'Einkäufer',
            displayOrder: 3,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'CEO / Director',
            value: 'Geschäftsführer/Inhaber',
            displayOrder: 4,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Creative Director',
            value: 'Creative Director',
            displayOrder: 1,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Designer',
            value: 'Designer',
            displayOrder: 2,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Head of Design',
            value: 'Head of Design',
            displayOrder: 5,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Marketing',
            value: 'Marketing',
            displayOrder: 6,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Partner',
            value: 'Partner',
            displayOrder: 7,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Product manager',
            value: 'Produktmanager',
            displayOrder: 9,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Sales',
            value: 'Vertrieb',
            displayOrder: 10,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Trainee',
            value: 'Praktikant',
            displayOrder: 10,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Other',
            value: 'Sonstiges',
            displayOrder: 11,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
    ],
    formSourceList: [
        {
            label: 'Request a Demo',
            value: 'Request a Demo',
        },
        {
            label: 'Apply a Free Trial',
            value: 'Apply a Free Trial',
        },
        {
            label: 'Need Technical Support',
            value: 'Need Technical Support',
        },
        {
            label: 'Learn more about Style3D Partner Program',
            value: 'Learn more about Style3D Partner Program',
        },
        {
            label: 'Subscribe to Style3D Newsletter',
            value: 'Subscribe to Style3D Newsletter',
        },
        {
            label: 'Having problems Downloading the Trail Software',
            value: 'Having problems Downloading the Trail Software',
        },
        {
            label: 'Other',
            value: 'Other',
        },
    ],
    productsInterestedIn: [
        {
            label: '3D Design',
            value: 'Style3D Studio',
            displayOrder: 0,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: '3D/2D.Connect',
            value: 'Style3D Assyst2D.Connect',
            displayOrder: 4,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Asset Management & Collaboration',
            value: 'Style3D Cloud (EU)',
            displayOrder: 2,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Costing',
            value: 'Assyst.Autocost',
            displayOrder: 7,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Digital Fabric',
            value: 'Style3D Fabric',
            displayOrder: 1,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Digital Knitwear',
            value: 'Digital Knitwear',
            displayOrder: 3,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Digital Product Creation',
            value: 'Digital Product Creation',
            displayOrder: 9,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Nesting & Order optimization',
            value: 'Assyst.Automarker',
            displayOrder: 6,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Pattern Design',
            value: 'Assyst.CAD',
            displayOrder: 5,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
        {
            label: 'Style3D CG',
            value: 'Style3D CG',
            displayOrder: 8,
            doubleData: 0,
            hidden: false,
            description: '',
            readOnly: false,
        },
    ],
};
