<template>
    <div class="apply-for-trial-form en" :class="formType">
        <!-- <div>
            <div id="hubspotForm" />
        </div> -->
        <AForm
            ref="applyForm"
            :model="formState.formInline"
            name="apply-for-trial"
            :rules="rules"
            :validate-messages="validateMessages"
            :layout="'horizontal'"
            :label-col="formState.labelCol"
            :wrapper-col="formState.wrapperCol"
        >
            <div class="scroll-y-container">
                <ARow v-for="formItemArr in filteredFormItems">
                    <ACol :span="24 / formItemArr.length" v-for="formItem in formItemArr">
                        <AFormItem
                            :key="formItem.key"
                            :name="formItem.key"
                            :label="formItem.label"
                            :class="formItem.key"
                            :label-col="formItemArr.length == 2 ? { span: 8 } : formState.labelCol"
                            :wrapper-col="formItemArr.length == 2 ? { span: 16 } : formState.wrapperCol"
                        >
                            <AInput
                                v-if="formItem.inputType === 'text'"
                                v-model:value="formState.formInline[formItem.key]"
                                :placeholder="formItem.tPlaceholder"
                                :type="'text'"
                                :maxlength="99"
                                autocomplete="off"
                            />
                            <TagCheckboxGroup
                                v-if="formItem.inputType === 'checkboxGroup'"
                                :list="formItem.options"
                                :checked-list="formState.formInline[formItem.key]"
                                :tip="formItem.tip"
                                :changeCheckedList="(l) => (formState.formInline[formItem.key] = l)"
                            />
                            <!-- <ACheckboxGroup
                                v-if="formItem.inputType === 'checkboxGroup'"
                                v-model:value="formState.formInline[formItem.key]"
                                class="custom-checkbox-group"
                            >
                                <ACheckbox
                                    v-for="option in formItem.options"
                                    :key="option.label"
                                    name="type"
                                    :value="option.value"
                                >
                                    {{ option.label }}
                                </ACheckbox>
                            </ACheckboxGroup> -->
                            <ASelect
                                v-else-if="formItem.inputType === 'selector'"
                                v-model:value="formState.formInline[formItem.key]"
                                :placeholder="formItem.tPlaceholder"
                                :get-popup-container="(triggerNode) => triggerNode.parentNode"
                                :dropdown-match-select-width="true"
                            >
                                <ASelectOption
                                    v-for="companyType in formItem.options"
                                    :key="companyType.label"
                                    :value="companyType.value"
                                >
                                    <ATooltip placement="topLeft">
                                        <template #title>
                                            <span> {{ companyType.label }}</span>
                                        </template>
                                        <span> {{ companyType.label }}</span>
                                    </ATooltip>
                                </ASelectOption>
                            </ASelect>

                            <ATextarea
                                v-if="formItem.inputType === 'textarea'"
                                v-model:value="formState.formInline[formItem.key]"
                                :placeholder="formItem.tPlaceholder"
                                :maxlength="99"
                                autocomplete="off"
                                auto-size
                            />
                        </AFormItem>
                    </ACol>
                </ARow>

                <AFormItem name="policyConfirm" class="policy-confirm" :wrapper-col="{ span: 24 }">
                    <ACheckbox v-model:checked="formState.policyConfirm">
                        <div class="policy-confirm-checkbox-text">
                            <span>{{ $t('global_home_header_form_conform_text') }}</span>
                            <CommonComponentsLink
                                :link-text="'global_home_header_form_conform_policy'"
                                @click.prevent="openPolicyFile('privacy')"
                            />
                        </div>
                    </ACheckbox>
                </AFormItem>
            </div>
            <div class="fixed-bottom-container">
                <AFormItem class="apply-for-trial-form-submit-btn" :wrapper-col="{ span: 24 }">
                    <CommonComponentsButtonLine
                        ref="submitBtn"
                        transparent
                        light
                        :button-text="t('global_link_button_7')"
                        :disabled="!formState.isFinished"
                        @click="onSubmit"
                    />
                </AFormItem>
            </div>
        </AForm>
    </div>
</template>

<script lang="ts" setup>
    import { useRouter } from 'vue-router';
    import { ref, onMounted, watch, onBeforeMount, reactive, computed, Ref } from 'vue';
    import type { Rule, FormInstance } from 'ant-design-vue/es/form';
    import { gsap } from 'gsap';
    import { ScrollTrigger, Observer } from 'gsap/all';
    import { postHubspotForm } from '~~/composables/useHubspot.ts';
    import { openPolicyFile } from '~~/composables/usePolicyFile.ts';
    import i18n from '@/locales';
    import message from '~~/antd//message/adapter';
    import optionEn from './optionEn';
    import optionRegionEn from './optionRegionEn';
    import TagCheckboxGroup from './TagCheckboxGroup.vue';

    gsap.registerPlugin(ScrollTrigger);
    const { t } = i18n.global;
    const router = useRouter();

    type hsFormKey =
        | 'user_type'
        | 'salutation'
        | 'title'
        | 'phone'
        | 'markt'
        | 'gewerbeart'
        | 'position'
        | 'firstname'
        | 'lastname'
        | 'email'
        | 'country'
        | 'city'
        | 'company'
        | 'message'
        | 'interests';

    const props = withDefaults(
        defineProps<{
            formType?: 'dialog' | 'mobile';
        }>(),
        { formType: 'dialog' },
    );

    const submitBtn = ref<HTMLButtonElement | null>(null);
    const isSubmitting = ref<boolean>(false);

    const emits = defineEmits<{
        (e: 'update:modelValue', value: boolean): void;
    }>();

    watch(
        isSubmitting,
        (value) => {
            emits('update:modelValue', value);
        },
        { immediate: true },
    );

    // ant-vue-design 校验信息模版，https://github.com/vueComponent/ant-design-vue/blob/main/components/form/utils/messages.ts
    /* eslint-disable  */
    const validateMessages = reactive({
        // required: () => t('global_validate_message_0') + '${label}!',
        required: (key) => {
            for (const formItemArr of formState.formConfig) {
                for (const item of formItemArr) {
                    if (item.key === key && item.inputType === 'selector') {
                        return 'Please select an option from the dropdown menu.';
                    }
                }
            }
            return 'Please complete this required field.';
        },
    });

    const validatePhone = async (_rule: Rule, value: string) => {
        if (value === '') {
            return Promise.reject('Please complete this required field.');
        } else if (/\d+$/.test(value)) {
            return Promise.resolve();
        } else {
            return Promise.reject(t('global_validate_message_2'));
        }
    };

    const validateEmail = async (_rule: Rule, value: string) => {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value === '') {
            return Promise.reject('Please complete this required field.');
        } else if (!re.test(String(value).toLowerCase())) {
            return Promise.reject(t('global_validate_message_4'));
        } else {
            return Promise.resolve();
        }
    };

    const validateWebsite = async (_rule: Rule, value: string) => {};

    // hubspot 表单数据 https://share-eu1.hsforms.com/1Kt_c5KwOQ-GPXApHtjuW9Af6b0c
    // 取自接口 https://forms-eu1.hsforms.com/embed/v3/form/25488444/2adfdce4-ac0e-43e1-8f5c-0a47b63b96f4/json?hs_static_app=forms-embed&hs_static_app_version=1.3812&X-HubSpot-Static-App-Info=forms-embed-1.3812&hutk=cedb96601dea875d9ca5716891dcd836

    // 表单信息
    const formState = reactive<{
        labelCol: {
            span: number;
        };
        wrapperCol: {
            span: number;
        };
        formInline: {
            user_type: string | null;
            salutation: string;
            title: string;
            phone: string;
            markt: string | null;
            gewerbeart: string | null;
            position: string | null;
            firstname: string;
            lastname: string;
            email: string;
            country: string | null;
            city: string;
            company: string;
            message: string;
            interests: Array<string>;
        };
        policyConfirm: boolean;
        isFinished: boolean;
        formConfig: Array<
            Array<{
                key: hsFormKey;
                dataType: string;
                inputType: string;
                name: string;
                label: string;
                tPlaceholder: string;
                business_user?: boolean;
                options?: Array<{
                    label: string;
                    value: string;
                }>;
            }>
        >;
    }>({
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        formInline: {
            user_type: null,
            salutation: '',
            title: '',
            phone: '',
            company: '',
            markt: null,
            gewerbeart: null,
            position: null,
            firstname: '',
            lastname: '',
            email: '',
            country: null,
            city: '',
            message: '',
            interests: [],
        },
        // companyTypeOption: [],
        policyConfirm: false,
        isFinished: false,
        // inputType 区分页面上输入框的样式
        formConfig: [
            [
                {
                    key: 'user_type',
                    dataType: 'string',
                    inputType: 'selector',
                    name: '用户类型',
                    label: 'User Type',
                    tPlaceholder: 'Please Select',
                    options: optionEn.userTypeList,
                },
            ],
            [
                {
                    key: 'salutation',
                    dataType: 'text',
                    inputType: 'text',
                    name: '称呼',
                    label: 'Salutation',
                    tPlaceholder: '',
                },
                {
                    key: 'title',
                    dataType: 'text',
                    inputType: 'text',
                    name: '标题',
                    label: 'Title',
                    tPlaceholder: '',
                    business_user: true,
                },
            ],
            [
                {
                    key: 'firstname',
                    dataType: 'text',
                    inputType: 'text',
                    name: '姓',
                    label: 'First Name',
                    tPlaceholder: '',
                },
                {
                    key: 'lastname',
                    dataType: 'text',
                    inputType: 'text',
                    name: '名',
                    label: 'Last Name',
                    tPlaceholder: '',
                },
            ],
            [
                {
                    key: 'email',
                    dataType: 'email',
                    inputType: 'text',
                    name: '邮箱',
                    label: 'Email',
                    tPlaceholder: 'Please use company email if you have',
                },
            ],
            [
                {
                    key: 'country',
                    dataType: 'text',
                    inputType: 'selector',
                    name: '国家',
                    label: 'Country/ Region',
                    tPlaceholder: 'Please Select',
                    options: optionRegionEn,
                },
                {
                    key: 'city',
                    dataType: 'text',
                    inputType: 'text',
                    name: '城市',
                    label: 'City',
                    tPlaceholder: '',
                },
            ],
            [
                {
                    key: 'phone',
                    dataType: 'tel',
                    inputType: 'text',
                    name: '电话号码',
                    label: 'Phone',
                    tPlaceholder: '',
                    business_user: true,
                },
            ],
            [
                {
                    key: 'company',
                    dataType: 'text',
                    inputType: 'text',
                    name: '企业',
                    label: 'Company',
                    tPlaceholder: '',
                },
            ],
            [
                {
                    key: 'markt',
                    dataType: 'text',
                    inputType: 'selector',
                    name: '细分市场',
                    label: 'Market',
                    tPlaceholder: 'Please Select',
                    business_user: true,
                    options: optionEn.marketSegmentList,
                },
            ],
            [
                {
                    key: 'gewerbeart',
                    dataType: 'text',
                    inputType: 'selector',
                    name: '行业',
                    label: 'Business',
                    tPlaceholder: 'Please Select',
                    business_user: true,
                    options: optionEn.businessList,
                },
            ],
            [
                {
                    key: 'position',
                    dataType: 'text',
                    inputType: 'selector',
                    name: '职位',
                    label: 'Position',
                    tPlaceholder: 'Please Select',
                    business_user: true,
                    options: optionEn.positionList,
                },
            ],
            [
                {
                    key: 'interests',
                    dataType: 'array',
                    inputType: 'checkboxGroup',
                    name: '感兴趣的产品',
                    label: 'Interests',
                    tPlaceholder: 'Please Check',
                    tip: '(Please select your fields of interests. Demo booking will be available shortly.)',
                    options: optionEn.productsInterestedIn,
                },
            ],
            [
                {
                    key: 'message',
                    dataType: 'text',
                    inputType: 'textarea',
                    name: '其他信息',
                    label: 'Message',
                    tPlaceholder: '',
                },
            ],
        ],
    });

    const isBusinessUser = () => {
        return formState.formInline.user_type === 'Business Users';
    };

    // 根据是否是商业用户 显示与隐藏一些表单字段
    const filteredFormItems = computed(() => {
        return formState.formConfig;
        // if (!isBusinessUser()) {
        //     return formState.formConfig.filter((item) => {
        //         return !item.business_user;
        //     });
        // } else {
        //     return formState.formConfig;
        // }
    });

    const checkRequired = (key: hsFormKey) => {
        return rules[key]?.[0]?.required ?? false;

        // const nonBusinessFields = formState.formConfig
        //     .filter((item) => {
        //         return !item.business_user;
        //     })
        //     .map((item) => item.key);

        // if (isBusinessUser() || (!isBusinessUser() && nonBusinessFields.indexOf(key) !== -1)) {
        //     // 商业用户 或 非商业用户且当前字段是非商业用户字段，进行必需性检验
        //     // console.log(key, rules[key]);
        //     return rules[key][0].required;
        // } else {
        //     return false;
        // }
    };

    // 校验规则，设置是否必需
    const rules: Record<string, Rule[]> = {
        user_type: [{ required: true, type: 'string', trigger: 'blur' }],
        salutation: [{ required: true, type: 'string', trigger: 'blur' }],
        phone: [{ required: true, type: 'string', validator: validatePhone, trigger: ['blur'] }],
        company: [{ required: true, type: 'string', trigger: 'blur' }],
        markt: [{ required: true, type: 'string', trigger: 'blur' }],
        gewerbeart: [{ required: true, type: 'string', trigger: 'blur' }],
        position: [{ required: true, type: 'string', trigger: 'blur' }],
        firstname: [{ required: true, type: 'string', trigger: 'blur' }],
        lastname: [{ required: true, type: 'string', trigger: 'blur' }],
        email: [{ required: true, type: 'email', validator: validateEmail, trigger: 'blur' }],
        country: [{ required: true, type: 'string', trigger: 'blur' }],
        message: [{ type: 'string' }],
        title: [{ type: 'string' }],
        city: [{ type: 'string' }],
        interests: [{ required: true, type: 'array', trigger: 'blur' }],
    };

    const genParams = () => {
        const params = Object.keys(formState.formInline).map((key) => {
            if (key !== 'interests')
                return {
                    name: key,
                    value: formState.formInline[key] || '',
                };
        });
        const productsListParams = formState.formInline['interests'].map((item) => {
            return {
                name: 'interests',
                value: item || '',
            };
        });
        return [...params, ...productsListParams].filter((item) => !!item);
    };

    // 表单提交回调
    const onSubmit = () => {
        (applyForm as Ref<FormInstance>).value.validate().then(async () => {
            if (isSubmitting.value) return;
            isSubmitting.value = true;

            postHubspotForm({
                submittedAt: new Date().getTime(),
                fields: genParams(),
                context: {
                    pageUri: window.location.href,
                },
            })
                .then((res) => {
                    submitSuccess();
                })
                .catch((err) => {
                    isSubmitting.value = false;
                    message.error(err._data.inlineMessage);
                });
        });
    };

    const { $bus } = useNuxtApp();
    const submitSuccess = () => {
        message.success(t('global_apply_trial_submit_success'));
        isSubmitting.value = false;
        applyForm.value?.resetFields();
        formState.isFinished = false;
        if (props.formType === 'mobile') {
            // 移动 端，关闭Header
            router.push('/thanks');
            $bus.$emit('close:mobileNav');
        } else {
            // PC 端，关闭弹窗
            router.push('/thanks');
            $bus.$emit('close:applyModal');
        }
    };

    const applyForm = ref<FormInstance | null>(null);

    const checkScrollSelector = (child: HTMLElement) => {
        // 获取所有下拉框
        const dropdown = document.querySelectorAll('.ant-select-dropdown');
        for (let i = 0; i < dropdown.length; i++) {
            const element = dropdown[i];
            // 如果下拉框包含子元素，则返回true
            if (element.contains(child)) return true;
        }
        // 否则返回false
        return false;
    };
    const addMouseWheelControl = () => {
        if (!matchMobile()) {
            const modalScroller = document.querySelector('.scroll-y-container') as HTMLElement;
            Observer.create({
                target: '.scroll-y-container', // can be any element (selector text is fine)
                type: 'wheel,touch', // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
                onUp: (self) => {
                    const { event } = self;

                    if (!checkScrollSelector(event.target as HTMLElement)) modalScroller.scrollBy(0, -25);
                },
                onDown: (self) => {
                    const { event } = self;

                    if (!checkScrollSelector(event.target as HTMLElement)) modalScroller.scrollBy(0, 25);
                },
            });
        }
    };

    onBeforeMount(async () => {
        // 内嵌表单方式 https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site
        // initHubSpot().then((res) => {
        //     // console.log(res);
        //     const form = hbspt.forms.create({
        //         region: 'eu1',
        //         portalId: '25488444',
        //         formId: '7eb66cd8-db5a-4749-8415-86b620ed00d3',
        //         target: '#hubspotForm',
        //     });
        //     console.log('form', form);
        // });
        // const res = await Http.get('/account/apply/create');
        // formState.optionEn.tel_code = res.data.create_info.tel_code;
        // formState.optionEn.company_type = res.data.create_info.company_type;
    });
    onMounted(() => {
        // todo  待优化，防抖
        watch([() => formState.policyConfirm, formState.formInline], ([value0, value1]) => {
            // function check() {
            if (
                value0 === true &&
                Object.keys(value1).filter((key) => {
                    if (key === 'policyConfirm') return false;
                    return checkRequired(key) && !value1[key];
                }).length === 0
            )
                (applyForm.value as FormInstance)
                    .validate()
                    .then(() => {
                        formState.isFinished = true;
                    })
                    .catch((err) => {
                        formState.isFinished = false;
                    });
            else formState.isFinished = false;
            // }
            // debounce(check, 1000);
        });

        // 由于打开弹窗冻结页面滚动，需要重新添加滚动控制
        addMouseWheelControl();
    });
</script>

<style lang="less" scoped>
    .apply-for-trial-form {
        .scroll-y-container {
            margin-left: 20px;
        }
        .ant-form .ant-form-item .ant-checkbox-group.custom-checkbox-group {
            grid-template-columns: 1fr !important;
        }
    }
</style>
