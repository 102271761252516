<template>
    <div class="comp-tag-checkbox-group">
        <div v-if="props.tip" class="tip-wrapper">
            <span class="tip">{{ props.tip }}</span>
        </div>
        <div
            v-for="item in props.list"
            :class="['checkbox-wrapper', { checked: props.checkedList.includes(item.value) }]"
            @click="onClick(item)"
        >
            <span class="text">{{ item.label }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
    const props = withDefaults(
        defineProps<{
            tip: string;
            list: Array<{ value: string; label: string }>;
            checkedList: Array<string>;
            changeCheckedList: (checkedList: Array<string>) => void;
        }>(),
        { list: [] },
    );

    const onClick = (item: { value: string; label: string }) => {
        let checkedList = [...props.checkedList];
        const findIndex = checkedList.findIndex((val) => val === item.value);
        if (findIndex === -1) {
            checkedList.push(item.value);
        } else {
            checkedList.splice(findIndex, 1);
        }
        props.changeCheckedList(checkedList);
    };
</script>

<style lang="less" scoped>
    .comp-tag-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        .tip-wrapper {
            width: 100%;
            margin-bottom: 12px;
            margin-top: 6px;
            .tip {
                color: #ffffff;
                font-size: 12px;
                opacity: 0.4;
            }
        }
        .checkbox-wrapper {
            margin-right: 8px;
            margin-bottom: 8px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 12px;
            background-color: #262626;
            border-radius: 15px;
            cursor: pointer;
            @media (max-width: @screen-mobile-width) {
                background-color: rgba(38, 38, 38, 0.4);
            }
            .text {
                color: #fff;
                font-size: 12px;
            }
            &.checked {
                background-color: #0032ff;
            }
        }
    }
</style>
