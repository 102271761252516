<template>
    <div class="header-flowing-text">
        <div class="flowing-text-content">
            <div v-if="isCN" :class="{ animate: enableFlowing }">
                <a class="link" :href="link.zh" target="_blank">Style3D Studio 6.2</a>
                柔体、刚体功能首次亮相，点击学新招，招招都很妙！
            </div>
            <div v-else :class="{ animate: enableFlowing }">
                Upgrade Alert: Get Ready for the Latest
                <a class="link" :href="link.en" target="_blank">Style3D Studio 6.2</a>
                Release!
            </div>
        </div>
        <CommonComponentsSVGCommonCross class="flowing-text-close-btn" @click="onHandelClose" />
    </div>
</template>

<script lang="ts" setup>
    import { ref, onMounted } from 'vue';
    const isCN = ifCNLocale();
    defineProps<{
        visible: boolean;
        text: string;
        onHandelClose: () => void;
    }>();

    const link = {
        en: 'https://landingpage.style3d.com/newsletter-contact-us-0-0-0-0-1-0-2-0-0-0-0',
        zh: 'https://landingpage.style3d.com/newsletter-contact-us-0-0-0-0-1-0-2-0-0-0',
    };

    const enableFlowing = ref(false);
    const initFlowing = () => {
        const textDiv = document.querySelector('.flowing-text-content > div') as HTMLDivElement;
        if (!textDiv) return;
        if (textDiv.scrollWidth > textDiv.offsetWidth) {
            enableFlowing.value = true;
        }
    };

    onMounted(() => {
        initFlowing();
    });
</script>

<style lang="less" scoped>
    .header-flowing-text {
        padding: 8px;
        width: .convert(1360px) [ @vw];
        margin: 0 auto;
        // height: 40px;
        font-size: 14px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .flowing-text-content {
            overflow: hidden;

            & div {
                word-break: keep-all;
                white-space: nowrap;

                &:hover {
                    animation-play-state: paused;
                }

                &.animate {
                    float: left;
                    animation: 12s wordsLoop linear infinite;
                }

                /* 添加动画，表示从右到左移动距离 */
                @keyframes wordsLoop {
                    0% {
                        transform: translateX(100%);
                    }
                    100% {
                        transform: translateX(-100%);
                    }
                }
            }
        }

        .flowing-text-close-btn {
            font-size: 20px;
            cursor: pointer;

            @media (max-width: @screen-mobile-width) {
                font-size: 24px;
            }
        }

        .link {
            text-decoration: underline;
            cursor: pointer;
        }
    }
</style>
