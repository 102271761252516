<template>
    <svg class="svg-close-icon" viewBox="0 0 24 24" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.8097 6.7015C19.1751 6.28059 19.1577 5.64251 18.7575 5.24228C18.3391 4.82386 17.6607 4.82386 17.2422 5.24228L11.9999 10.4847L6.75747 5.24228L6.70147 5.19008C6.28056 4.82465 5.64247 4.84205 5.24224 5.24228C4.82383 5.6607 4.82383 6.33909 5.24224 6.75751L10.4846 11.9999L5.24224 17.2423L5.19004 17.2983C4.82462 17.7192 4.84202 18.3573 5.24224 18.7575C5.66066 19.1759 6.33906 19.1759 6.75747 18.7575L11.9999 13.5151L17.2422 18.7575L17.2983 18.8097C17.7192 19.1751 18.3572 19.1577 18.7575 18.7575C19.1759 18.3391 19.1759 17.6607 18.7575 17.2423L13.5151 11.9999L18.7575 6.75751L18.8097 6.7015Z"
            fill="white"
        />
    </svg>
</template>

<style lang="less" scoped>
    .svg-close-icon {
        height: 1em;
        width: 1em;
    }
</style>
